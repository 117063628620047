@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

$max-column-width: 1224 * tokens.$bpk-one-pixel-rem;

@mixin falcon-text-component-max-width {
  max-width: tokens.bpk-spacing-md() * 97;
}

@mixin custom-pages-grid {
  @include breakpoints.bpk-breakpoint-tablet {
    margin-right: tokens.$bpk-breakpoint-tablet-margin;
    margin-left: tokens.$bpk-breakpoint-tablet-margin;
  }

  @include breakpoints.bpk-breakpoint-small-tablet {
    margin-right: tokens.$bpk-breakpoint-small-tablet-margin;
    margin-left: tokens.$bpk-breakpoint-small-tablet-margin;
  }

  @include breakpoints.bpk-breakpoint-mobile {
    margin-right: tokens.$bpk-breakpoint-mobile-margin;
    margin-left: tokens.$bpk-breakpoint-mobile-margin;
  }

  @include breakpoints.bpk-breakpoint-small-mobile {
    margin-right: tokens.$bpk-breakpoint-small-mobile-margin;
    margin-left: tokens.$bpk-breakpoint-small-mobile-margin;
  }

  @include breakpoints.bpk-breakpoint-above-tablet {
    margin-right: tokens.$bpk-breakpoint-desktop-margin;
    margin-left: tokens.$bpk-breakpoint-desktop-margin;
  }

  @include breakpoints.bpk-breakpoint-above-desktop {
    width: 100%;
    max-width: $max-column-width;
    margin-right: auto;
    margin-left: auto;
  }
}
