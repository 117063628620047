// stylelint-disable unit-disallowed-list,selector-max-compound-selectors,selector-max-id,declaration-no-important
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/typography';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use 'mixins';

$max-column-width: 1224px;
$sidebar-width: 300px;
$column-gap: 117px;

#home-container {
  &.homecontent {
    display: table;
    width: 100%;
    height: 282px;
    margin: 0 auto;
    padding: tokens.bpk-spacing-lg();
    background-color: tokens.$bpk-surface-contrast-day;

    @include breakpoints.bpk-breakpoint-mobile {
      height: auto;
      min-height: 0;
      padding: 0;
    }

    .wrap-homecontent {
      position: relative;
      display: table-cell;
      vertical-align: middle;

      #value-message-container {
        max-width: $max-column-width;
        margin: 0 auto;
        padding: tokens.bpk-spacing-base() 0 tokens.bpk-spacing-xl();
        color: tokens.$bpk-surface-default-day;
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        font-size: tokens.$bpk-one-pixel-rem * 42;

        @include breakpoints.bpk-breakpoint-mobile {
          display: none;
        }
      }
    }
  }
}

.landing-fli-arrivals-container {
  .content {
    width: 95%;
    max-width: $max-column-width;
    margin: 0 auto;
    overflow: auto;
  }

  .title-wrap {
    margin-top: tokens.bpk-spacing-xl();
    margin-bottom: tokens.bpk-spacing-xl();
    padding-left: tokens.bpk-spacing-base();
    border-left: tokens.$bpk-border-size-xl solid
      tokens.$bpk-status-warning-spot-day;
    line-height: tokens.$bpk-line-height-xl;
    text-align: start;

    @include utils.bpk-rtl {
      padding-right: tokens.bpk-spacing-base();
      padding-left: 0;
      border-right: tokens.$bpk-border-size-xl solid
        tokens.$bpk-status-warning-spot-day;
      border-left: none;
    }
  }

  .subtitle {
    display: block;
    color: tokens.$bpk-text-secondary-day;
    font-size: tokens.$bpk-font-size-lg;
  }

  .breadcrumbs {
    border-top: tokens.$bpk-border-size-sm solid
      tokens.$bpk-surface-highlight-day;
  }

  .strevda-container {
    margin: tokens.$bpk-line-height-sm 0;
    text-align: center;
    overflow: hidden;
  }

  .adslot {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
}

.landing-fli-arrivals-container-seo {
  /* stylelint-disable-next-line selector-id-pattern */
  #ContentBlock div[class*='contentfulTemplate'] {
    h2 {
      margin: revert;
      padding: revert;
      font-size: tokens.$bpk-font-size-xl;
    }

    h3 {
      margin: revert;
      padding: revert;
      font-size: tokens.$bpk-font-size-lg;
    }

    p {
      margin: revert;
    }
  }

  .subtitle {
    display: none;
  }

  .breadcrumbs {
    padding: tokens.bpk-spacing-md() 0 0;
  }

  .title-wrap {
    margin: tokens.bpk-spacing-xl() 0 tokens.bpk-spacing-base();
    padding-left: 0;
    border-left: none;

    @include utils.bpk-rtl {
      padding-right: 0;
      border-right: none;
    }
  }
}

.landing-fli-arrivals-container,
.landing-fli-arrivals-container-seo {
  #search-controls-wrapper {
    margin-top: tokens.bpk-spacing-xxxl();
    margin-bottom: tokens.bpk-spacing-xxl();

    @include breakpoints.bpk-breakpoint-mobile {
      margin-top: tokens.bpk-spacing-xxxl();
      margin-bottom: tokens.bpk-spacing-xl();
    }
  }

  #arrival-departures-cross-links {
    width: 95%;
    max-width: $max-column-width;
    margin: 0 auto;
    margin-bottom: tokens.bpk-spacing-xl();
    overflow: auto;
  }
}

.main-wrapper {
  margin: auto;

  .feature-container {
    div.no-margin {
      margin: 0;
    }
  }

  div[class*='bpk-section-header'] {
    h2 {
      @include typography.bpk-heading-2;

      @include breakpoints.bpk-breakpoint-mobile {
        @include typography.bpk-heading-3;
      }
    }
  }
}

.main-section {
  width: 100%;
  padding: 0 tokens.bpk-spacing-base();

  .main {
    max-width: $max-column-width;
    margin: 0 auto;

    h1 {
      font-size: tokens.$bpk-font-size-xxl;
      line-height: tokens.$bpk-line-height-xxl;

      @include breakpoints.bpk-breakpoint-mobile {
        padding-bottom: tokens.bpk-spacing-sm();
      }

      &.with-subheading {
        padding-bottom: 0;

        @include breakpoints.bpk-breakpoint-mobile {
          padding-bottom: 0;
        }
      }
    }

    div[class*='bpk-section-header'] {
      h2 {
        @include typography.bpk-heading-2;

        @include breakpoints.bpk-breakpoint-mobile {
          @include typography.bpk-heading-3;
        }
      }
    }

    .main-header {
      max-width: tokens.$bpk-one-pixel-rem * 720;
      margin: 0;
      padding-bottom: tokens.bpk-spacing-xl();
      font-size: tokens.$bpk-font-size-xxxxl;
      font-weight: bold;
      line-height: tokens.$bpk-line-height-xxxxl;
    }
  }
}

// replace this to tokens.$bpk-color-sky-white after upgrading bpk
$color-white: tokens.$bpk-surface-default-day;

.custom-background-styling {
  margin-bottom: -1em;
  padding-bottom: 1em;
  background-color: $color-white;
}

// Keep the same distance between the div modules on the page
.module-box {
  margin-bottom: tokens.bpk-spacing-lg();

  @include breakpoints.bpk-breakpoint-above-mobile {
    margin-bottom: 2 * tokens.bpk-spacing-xl();
  }

  div[class*='exploreButtonContainer'] {
    padding-bottom: 0;
  }
}

.wrap-header {
  display: table-row;

  .main {
    .main-header {
      max-width: 100%;
      padding-bottom: tokens.bpk-spacing-base();
      color: tokens.$bpk-surface-default-day;
      font-size: tokens.$bpk-font-size-xxxl;

      @include breakpoints.bpk-breakpoint-mobile {
        margin-right: tokens.bpk-spacing-md();
        padding-top: tokens.bpk-spacing-md();
        padding-bottom: tokens.bpk-spacing-md();
        padding-left: tokens.bpk-spacing-md();
        font-size: tokens.$bpk-font-size-xxl;
        line-height: tokens.$bpk-line-height-xxl;
      }
    }
  }
}

/* -- Custom landing page styles -- */

#custom-page-nav-wrapper {
  position: relative;
  z-index: 10;

  #custom-page-global-header.custom-page-global-header {
    &--hero-day {
      background-color: tokens.$bpk-surface-hero-day;

      header {
        background-color: tokens.$bpk-surface-hero-day;
      }
    }

    &--with-padding {
      header {
        // This a temporary fix for the header spacing, copied from https://github.skyscannertools.net/identity/profile/blob/ca8022a4c1a41b4552d33908fa1578850b81f74a/client/src/components/Header/Header.scss#L5
        padding-bottom: tokens.bpk-spacing-md();
      }
    }
  }
}

body.custom-menu-open {
  @include breakpoints.bpk-breakpoint-small-tablet {
    #custom-page-nav-wrapper {
      min-height: 100vh;
      background-color: tokens.$bpk-surface-hero-day;
    }

    #custom-page-body-wrapper {
      display: none;
    }
  }
}

.custom-page-content {
  display: flex;
  padding-bottom: tokens.bpk-spacing-xxxl();
  flex-direction: column;
  row-gap: tokens.bpk-spacing-xxxl();

  @include breakpoints.bpk-breakpoint-above-tablet {
    padding-bottom: tokens.bpk-spacing-xxxxl();
    row-gap: tokens.bpk-spacing-xxxxl();
  }

  &--category {
    padding-top: tokens.bpk-spacing-xxl();

    @include breakpoints.bpk-breakpoint-above-tablet {
      padding-top: tokens.bpk-spacing-xxxxl();
    }
  }

  &--article,
  &--custom-page,
  &--media {
    padding-top: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-above-tablet {
      padding-top: tokens.bpk-spacing-xxxl();
    }
  }

  &--visa {
    padding-top: 0;
  }

  &--archive {
    padding-top: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-above-tablet {
      padding-top: tokens.bpk-spacing-xxl();
    }
  }

  &--travel-trends {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.custom-page {
  &__full-width-layout {
    .custom-page__component--fixed-width {
      @include mixins.custom-pages-grid;
    }

    .custom-page__author {
      @include mixins.custom-pages-grid;
    }
  }

  &__two-column-layout {
    display: flex;
    column-gap: $column-gap;
    flex-direction: column-reverse;
    row-gap: tokens.bpk-spacing-xxl();

    @include breakpoints.bpk-breakpoint-tablet {
      .custom-page__component--fixed-width {
        @include mixins.custom-pages-grid;
      }
    }

    @include breakpoints.bpk-breakpoint-above-tablet {
      flex-direction: row;
      row-gap: tokens.bpk-spacing-xxxl();

      @include mixins.custom-pages-grid;
    }

    .custom-page__main {
      flex: 1;
    }
  }

  &__main {
    display: flex;
    min-width: 0;
    flex-direction: column;
    row-gap: tokens.bpk-spacing-xxl();

    @include breakpoints.bpk-breakpoint-above-tablet {
      row-gap: tokens.bpk-spacing-xxxl();
    }

    // override for wordpress styles
    .single-post__main_content {
      max-width: 100%;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    row-gap: tokens.bpk-spacing-xxxl();

    @include breakpoints.bpk-breakpoint-tablet {
      @include mixins.custom-pages-grid;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      .mobile-hidden {
        display: none;
      }
    }

    @include breakpoints.bpk-breakpoint-above-tablet {
      flex: 0 0 $sidebar-width;
      row-gap: tokens.bpk-spacing-xl();
    }

    &--header-layout-column {
      display: none;

      @include breakpoints.bpk-breakpoint-above-tablet {
        display: block;
      }
    }

    .custom-page-content--visa & {
      @include breakpoints.bpk-breakpoint-tablet {
        display: none;
      }
    }
  }
}

// Inspired by banana https://github.skyscannertools.net/dingo/banana/blob/main/packages/webapp/src/scss/breakpoints.scss
@mixin breakpoint-above-desktop {
  @media #{$bpk-breakpoint-query-above-desktop} {
    @content;
  }
}

// Inspired by banana https://github.skyscannertools.net/dingo/banana/blob/main/packages/webapp/src/scss/padding.scss
.custom-page-fixed-width {
  @include mixins.custom-pages-grid;
}

/* -- End Custom landing page styles -- */

/* -- TODO Removed after OC have all migrated to GC and modified width -- */
.header-wrapper {
  @include breakpoints.bpk-breakpoint-above-mobile {
    padding-bottom: 12 * tokens.$bpk-one-pixel-rem;
    background-color: tokens.$bpk-surface-contrast-day;
  }
}

.header-wrapper-mobile-apps {
  padding-bottom: tokens.bpk-spacing-md();
  background-color: tokens.$bpk-surface-contrast-day;
}

#header-root div[class*='Header__wrapper'] {
  width: 1224 * tokens.$bpk-one-pixel-rem;

  @media screen and (max-width: tokens.$bpk-breakpoint-desktop) {
    width: 95%;
  }

  @media screen and (max-width: tokens.$bpk-breakpoint-mobile) {
    width: 100%;
  }
}

#footer-flags-root div[class*='FooterFlags'] {
  max-width: 1224 * tokens.$bpk-one-pixel-rem;
}

#flights-search-controls-root div[class*='flights-sc-wrapper'] {
  max-width: 1224 * tokens.$bpk-one-pixel-rem;
}

/* ---------------------------------------------- */
